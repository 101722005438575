import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";

const FIREBASE_CONFIG: FirebaseOptions = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPID,
  measurementId: process.env.VUE_APP_MEASUREMENTID,
}

const FIREBASE_APP = initializeApp(FIREBASE_CONFIG);
const FIREBASE_ANALYTICS = getAnalytics(FIREBASE_APP as FirebaseApp);
const FIREBASE_AUTH = getAuth(FIREBASE_APP as FirebaseApp);

logEvent(FIREBASE_ANALYTICS, "app_start");

export function event (event: string, params?: object): void {
  logEvent(FIREBASE_ANALYTICS, event, { params });
}

onAuthStateChanged(FIREBASE_AUTH, (user) => {
  if (!user) signInAnonymously(FIREBASE_AUTH);
});

export function currentUser (): {
  "uid": string;
} {
  return FIREBASE_AUTH.currentUser as {
    "uid": string;
  }
}
